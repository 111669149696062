/*
 * Действия с выделенными сообщениями в чате
 */
const goal = (message: string) => window.goal(
  'chatMultiMessageActions',
  { chatMultiMessageActions: message },
)

export const meetingClick = () => goal('Создать встречу')
export const replyClick = () => goal('Ответить')
export const copyClick = () => goal('Скопировать текст')
export const deleteClick = () => goal('Удалить для всех')
export const unselectAllClick = () => goal('Снять выделение')
export const toggleImportantClick = () => goal('Отметить как важное / Убрать из важных')
export const forwardHereClick = () => goal('Переслать сюда')
export const forwardClick = () => goal('Переслать')
