//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import ContactStatusBadges from '@/components/UI/ContactStatusBadges.vue'
import { activeCallStore, callsStore, teamsStore, uiStore } from '@/store'
import { defaultLogger } from '@/loggers'
import router from '@/router'
import {
  goalChatContactPopupCalendarClick,
  goalChatContactPopupCalendarMyClick,
  goalChatContactPopupCallClick,
  goalChatContactPopupMessageClick,
  goalChatContactPopupProfileClick,
  goalChatContactPopupProfileMyClick,
} from '@/analytics'

export default {
  name: 'UserInfoWindow',

  components: {
    ContactStatusBadges,
    OnlineStatus: () => import('@/components/Chat/OnlineStatus'),
  },

  props: {
    jid: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: false,
    },
  },

  computed: {
    ...mapGetters([
      'chatType',
      'currentChat',
      'entity',
      'profile',
    ]),
    actions () {
      if (window.isHeadless) return null

      const possibleActions = [
        this.openProfileAction,
        this.openCalendarAction,
        this.sendMessageAction,
        this.callAction,
      ]
      return possibleActions.filter(a => a !== null)
    },
    openProfileAction () {
      if (this.currentChatType === 'task') return null

      return {
        label: this.$t('glossary.profile'),
        action: this.openProfile,
      }
    },
    sendMessageAction () {
      if (
        !this.currentEntity.canSendMessage ||
        this.jid === this.currentChat
      ) return null

      return {
        label: this.$t('common.writeMessage'),
        action: this.sendMessage,
      }
    },
    openCalendarAction () {
      return {
        label: this.jid === this.profile.jid
          ? this.$t('common.openMyCalendar')
          : this.$t('common.openCalendar'),
        action: this.openCalendar,
      }
    },
    callAction () {
      if (
        !this.currentEntity.canCall ||
        !callsStore.getters.isCallingEnabled() ||
        activeCallStore.state.activeCall?.jid === this.jid
      ) return null

      return {
        label: this.$t('common.callTo'),
        action: this.call,
      }
    },
    showOnlineStatus () {
      return this.currentChatType === 'direct'
    },
    currentChatType () {
      return this.chatType(this.jid)
    },
    currentEntity () {
      return this.entity(this.jid)
    },
    description () {
      switch (this.currentChatType) {
        case 'group':
          return this.currentEntity.description
        case 'direct':
          return this.currentEntity.role
      }
      return null
    },
    // potential undefined and can destroy chat
    displayName () {
      return this.currentEntity?.displayName || ''
    },
  },
  methods: {
    openProfile () {
      this.jid === this.profile.jid
        ? goalChatContactPopupProfileMyClick()
        : goalChatContactPopupProfileClick()

      const payload = this.jid

      let instance
      if (this.currentChatType === 'group') {
        instance = 'group-profile'
      } else if (this.currentChatType === 'direct') {
        instance = 'contact-profile'
      }
      uiStore.actions.switchRightBarInstance({
        instance,
        payload,
      })
      this.close && this.close()
    },
    sendMessage () {
      goalChatContactPopupMessageClick()

      router.push({
        name: 'Chat',
        params: {
          teamId: teamsStore.getters.currentTeam.uid,
          jid: this.jid,
        },
      })
      this.close && this.close()
    },
    openCalendar () {
      this.jid === this.profile.jid
        ? goalChatContactPopupCalendarMyClick()
        : goalChatContactPopupCalendarClick()

      const teamId = teamsStore.getters.currentTeam.uid
      const contactId = this.jid === this.profile.jid ? undefined : this.jid
      router.push({
        name: 'Calendar',
        params: contactId ? { teamId, contactId } : { teamId },
      })
      this.close && this.close()
    },
    call () {
      goalChatContactPopupCallClick()

      const type = callsStore.getters.preferredCallType
      if (!type) {
        defaultLogger.error('Tried calling when preferred call type is null')
      } else {
        activeCallStore.actions.startCall({
          jid: this.jid,
          type,
          startBuzzing: true,
        })
      }
      this.close && this.close()
    },
  },
}
