const g = window.goal

export * as goalChatMultiMessageActions from './chatMultiMessageActions'
export * as goalChatOneMessageActions from './chatOneMessageActions'
export * as goalTaskControls from './taskControls'
export * as goalMessageCommentControls from './messageCommentControls'

export const goalChatContactPopupCalendarClick = () => g('chatContactPopup', { chatContactPopup: 'Открыть чужой календарь' })
export const goalChatContactPopupCalendarMyClick = () => g('chatContactPopup', { chatContactPopup: 'Открыть свой календарь' })
export const goalChatContactPopupCallClick = () => g('chatContactPopup', { chatContactPopup: 'Позвонить' })
export const goalChatContactPopupMessageClick = () => g('chatContactPopup', { chatContactPopup: 'Перейти в чат' })
export const goalChatContactPopupOpenClick = () => g('chatContactPopup', { chatContactPopup: 'Открыть чужой попап' })
export const goalChatContactPopupOpenMyClick = () => g('chatContactPopup', { chatContactPopup: 'Открыть свой попап' })
export const goalChatContactPopupProfileClick = () => g('chatContactPopup', { chatContactPopup: 'Чужой профиль' })
export const goalChatContactPopupProfileMyClick = () => g('chatContactPopup', { chatContactPopup: 'Свой профиль' })
export const goalTeamsBarDashboardOpen = (expanded: boolean) => g('teamsBar', { teamsBar: 'Открыть Доску задач', expanded: String(expanded) })
export const goalTeamsBarDashboardClose = (expanded: boolean) => g('teamsBar', { teamsBar: 'Закрыть Доску задач', expanded: String(expanded) })
export const goalTeamsBarCalendarOpen = (expanded: boolean) => g('teamsBar', { teamsBar: 'Открыть Календарь', expanded: String(expanded) })
export const goalTeamsBarCalendarClose = (expanded: boolean) => g('teamsBar', { teamsBar: 'Закрыть Календарь', expanded: String(expanded) })
export const goalTeamsBarCollapseClick = () => g('teamsBar', { teamsBar: '«Свернуть» колонку команд' })
export const goalTeamsBarCreateTeamClick = (expanded: boolean) => g('teamsBar', { teamsBar: 'Создание команды', expanded: String(expanded) })
export const goalTeamsBarExpandClick = () => g('teamsBar', { teamsBar: '«Развернуть» колонку команд' })
export const goalTeamsBarHelpClick = (expanded: boolean) => g('teamsBar', { teamsBar: '«Помощь»', expanded: String(expanded) })
export const goalTeamsBarManualClick = (expanded: boolean) => g('teamsBar', { teamsBar: '«Руководство» в меню «Помощь»', expanded: String(expanded) })
export const goalTeamsBarReleaseNotesClick = (expanded: boolean) => g('teamsBar', { teamsBar: '«Обновления» в меню «Помощь»', expanded: String(expanded) })
export const goalTeamsBarSupportClick = (expanded: boolean) => g('teamsBar', { teamsBar: '«Поддержка» в меню «Помощь»', expanded: String(expanded) })
export const goalTeamsBarWishlistClick = (expanded: boolean) => g('teamsBar', { teamsBar: '«Хотелки» в меню «Помощь»', expanded: String(expanded) })
