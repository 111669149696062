import { teamsStore } from '@/store'

const goal = (message: string) => window.goal(
  'messageCommentControls',
  {
    taskControls: message,
    teamId: teamsStore.getters.currentTeam.uid,
  },
)

export const commentMessageByMessagesClick = () => goal('Нажатие кнопки "комментировать" в опциях сообщения')
