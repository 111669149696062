/*
 * Действия с сообщением в чате
 */
const goal = (message: string) => window.goal(
  'chatOneMessageActions',
  { chatOneMessageActions: message },
)

export const meetingClick = () => goal('Создать встречу')
export const forwardClick = () => goal('Переслать')
export const copyClick = () => goal('Скопировать текст')
export const importantClick = (on: boolean) => on ? goal('Отметить как важное') : goal('Убрать из важных')
export const editClick = () => goal('Изменить')
export const deleteClick = () => goal('Удалить для всех')
export const pinClick = () => goal('Закрепить сообщение')
export const unpinClick = () => goal('Открепить сообщение')
export const hidePreviewClick = () => goal('Убрать предпросмотр')
export const showReactionsClick = () => goal('Клик «Добавить реакцию»')
export const showMoreReactionsClick = () => goal('Клик «Добавить ещё реакцию»')
export const addReactionClick = () => goal('Добавить реакцию')
export const addSomeoneReactionClick = () => goal('Добавить реакцию кликом по чужой')
export const removeReaction = () => goal('Убрать реакцию')
export const ellipsisClick = () => goal('Нажать «...»-меню')
export const replyDblclick = () => goal('Даблклик для ответить')
export const replyClick = () => goal('Ответить')
