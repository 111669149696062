














import { Contact } from '@tada-team/tdproto-ts'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ContactStatusBadges extends Vue {
  @Prop({ type: Contact, required: true }) private readonly contact!: Contact

  private get statuses (): Array<{ color: string, label: string }> {
    const arr = []

    if (this.contact.jid === this.$store.getters.getUserId) {
      arr.push({ color: 'td-gray-9', label: this.$t('common.you').toString() })
    }

    switch (this.contact.status) {
      case 'admin':
        arr.push({
          color: 'main-accent',
          label: this.$t('common.admin').toString(),
        })
        break
      case 'guest':
        arr.push({
          color: 'td-gray-9',
          label: this.$t('common.guest').toString(),
        })
        break
      case 'owner':
        arr.push({
          color: 'main-accent',
          label: this.$t('common.teamOwner').toString(),
        })
        break
    }

    return arr
  }
}
